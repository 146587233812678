import React from 'react'
import { Box, Heading, Columns, Card, List, Tile, Image } from 'react-bulma-components/dist';
import { convertLineGapsToComma, dateToDateString, dateToTimeString } from '../utilities/helper';
import { clientLogoUrl, clientMenuLogoUrl } from '../ClientInfo/clientInfo';
import { imageUrlForPostures, PrintSheetType } from '../utilities/enums';
import { getCurrentLocation, getCurrentUser } from '../utilities/Store';


const pageHeader = (sheetType, clientLogoUrl) => {
    // D
    // sheetType = ORDER DETAILS  |  PRODUCT DETIALS
    // clientLogoUrl = ClientLogo

    const dateString = dateToTimeString(new Date())
    const cl = getCurrentLocation()
    // const cu = getCurrentUser()
    let dateTimeLocString = cl.name + " • " + dateString



    return (
        <div>
            <Columns className=" is-mobile">
                <Columns.Column size={9} className="">
                    <Heading className=" is-size-6 has-text-weight-bold has-text-grey-light marginTop10"> {sheetType} </Heading>
                </Columns.Column>
                <Columns.Column className="">
                    <img className=' ' src={clientMenuLogoUrl} alt="Brand Logo" width="160" />
                </Columns.Column>
            </Columns>
            <hr className="solid heavyHr topMarginMinus20" />
            <Heading subtitle className=" type50 topMarginMinus20 has-text-weight-semibold has-text-grey has-text-right"> {dateTimeLocString} </Heading>
        </div>

    )

}





const orderHeader = (orderNo, posNo, productCount, orderTrialDate, orderDeliveryDate) => {

    // D
    // orderNo = 201224ZHO
    // posNo = 112212
    // productCount = 2
    // orderTrialDate = 31-Jan-2021
    // orderDeliveryDate = 4-Feb-2021

    const orderTrialDateString = orderTrialDate ? dateToDateString(orderTrialDate) : ""
    const orderDeliveryDateString = orderDeliveryDate ? dateToDateString(orderDeliveryDate) : ""


    return (
        <div>
            <div className="">
                <Heading size={5} className=" marginBottom0"> #{orderNo} </Heading>
                <Heading className=" type70 has-text-grey topMarginMinus20"> (POSNo-{posNo}) • {productCount} Product </Heading>
                <Heading subtitle className=" type70 has-text-grey"> Order Trial: {orderTrialDateString} &nbsp; · &nbsp; Order Delivery: {orderDeliveryDateString} </Heading>
            </div>
        </div>
    )
}


const measurementCard = (name, measures) => {
    // D
    // name = MEASUREMENT NAME
    // measures = [ { name: Height, value: 175, unit: cm }, ... ]

    if (!measures.length) {
        return null
    }

    return (
        <Tile key={name} kind='parent' className='  '>
            <Tile className='' >
                <div className=' printMeasuremntCard pad0 has-background-light ' >
                    <List className=" has-background-light  type50 has-text-weight-semibold " >
                        <p className=" pad10  type50     "> {name} </p>
                        {/* <List.Item>
                            <Columns className="is-mobile">
                                <Columns.Column size={8}> Height</Columns.Column>
                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                            </Columns>
                        </List.Item> */}
                        {
                            measures.map(thisMeasure => {
                                return <List.Item key={thisMeasure.name + thisMeasure.value}>
                                    <Columns className="is-mobile">
                                        <Columns.Column size={6}> {thisMeasure.name}</Columns.Column>
                                        <Columns.Column className="has-text-right">  {thisMeasure.value} {thisMeasure.unit} </Columns.Column>
                                    </Columns>
                                </List.Item>
                            })
                        }

                    </List>
                </div>
            </Tile>
        </Tile>

    )
}


const clientBodyPostureImages = (clientBodyPics) => {

    // D
    // clientBodyPics = [CustomerPicObj1, CustomerPicObj2] 

    if (!clientBodyPics.length) {
        return null
    }

    return (
        <div>
            <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom6"> POSTURE IMAGES </p>
            <Tile kind="ancestor" className=' printBodyImageGrid ' >
                {/* <Tile kind='parent' className=' '>
                    <Tile className='' >
                        <Box className=' printBodyImageCard pad0  ' >
                            <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                        </Box>
                    </Tile>
                </Tile> */}

                {
                    clientBodyPics.map(thisCustomerPic => {
                        return (
                            <Tile kind='parent' className=' is-shadowless '>
                                <Tile className=' is-shadowless' >
                                    <Box className=' printBodyImageCard pad0 is-shadowless  ' >
                                        <Image rounded={false} className='  ' src={thisCustomerPic.url} />
                                    </Box>
                                </Tile>
                            </Tile>
                        )
                    })
                }
            </Tile>
        </div>
    )
}



const clientMeasurements = (clientMeasurements) => {
    // clientMeasurements = ["measurement1_Object", "measurement2_Object"] 
    return (
        <div>
            <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom8"> MEASUREMENTS </p>
            <Tile kind="ancestor" className=' printMeasurementGrid ' >
                {
                    clientMeasurements.map(thisMeasurement => {
                        return measurementCard(thisMeasurement.name, thisMeasurement.measures)
                    })
                }
            </Tile>
        </div>

    )
}


const clientPostures = (postures) => {
    // postureImageUrls = ["posture1_ImageUrl", "posture2_ImageUrl"] 
    return (
        <div>
            <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom6"> POSTURES </p>
            <Tile kind="ancestor" className=' printPostureGrid ' >
                {/* <Tile kind='parent' className=' '>
                    <Tile className='' >
                        <Box className=' printPostureCard pad0  ' >
                            <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
                        </Box>
                    </Tile>
                </Tile> */}

                {
                    postures.map(ps => {
                        return (
                            <Tile kind='parent' key={ps} className=' '>
                                <Tile className='' >
                                    <Box className=' printPostureCard pad0 is-shadowless has-background-white pad4' >
                                        <Image rounded={false} className='  ' src={imageUrlForPostures(ps)} />
                                        <p className="has-text-centered type45 has-text-grey-light "> {ps} </p>
                                    </Box>
                                </Tile>
                            </Tile>
                        )
                    })
                }
            </Tile>
        </div>
    )
}

const clientPatterns = (patternStrings) => {
    // patternStrings = ["Jacket · 1142", "Suit 2 Piece · 265763"] 
    return (
        <Columns.Column className=''>
            <p className="has-text-grey-light  has-text-weight-semibold marginBottom6"> PATTERNS </p>
            <div className=" ">
                {/* <p>Suit 2 Peace · 164672</p> */}
                {patternStrings.map((pt, index) => {
                    return <p key={index}>{pt}</p>
                })}
            </div>
        </Columns.Column>
    )
}

const clientDetails = (name, measurementNote, height, weight, shoeSize, heightUnit, weightUnit, shoeSizeUnit, patterns, postures, measurements, pics) => {
    // name = Elon Musk
    // heightString = 175 cm
    // weightString = 70 kg
    // shoeSizeString = 8 inch
    // patternStrings = ["Jacket · 1142", "Suit 2 Piece · 265763"] 
    // postureImageUrls = ["posture1_ImageUrl", "posture2_ImageUrl"] 
    // clientMeasurements = ["measurement1_Object", "measurement2_Object"] 
    // clientBodyImageUrls = ["body_ImageUrl", "body_ImageUrl"] 

    let measureNoteString = convertLineGapsToComma(measurementNote ?? "")
    let heightString = height ? " " + height + " " + (heightUnit ?? " ") : null
    let weightString = weight ? " " + weight + " " + (weightUnit ?? " ") : null
    let shoeSizeString = shoeSize ? " " + shoeSize + " " + (shoeSizeUnit ?? " ") : null

    let allPatterns = patterns ? patterns : []
    let allPostures = postures ? postures : []
    let c_measurements = measurements ? measurements : []
    let clientBodyPics = pics ? pics : []

    

    return (
        <div>
            <Card className=' has-background-light marginTop10  is-shadowless '>
                <Card.Header className=' pad10 is-shadowless'>
                </Card.Header>
                <Card.Content >
                <Heading size={6} className="has-text-grey-light   is-fullwidth"> Client: <span className="has-text-grey">{name}</span> </Heading>

                    {
                        measureNoteString ?
                            <div>
                                <p className="has-text-grey-light has-text-weight-semibold type65  marginBottom6 "> Note : <span className="has-text-grey has-text-weight-normal">{measureNoteString}</span></p>
                                <br />
                            </div>
                            : null
                    }
                    <Columns className=' type60'>
                        <Columns.Column className=''>
                            <p className="has-text-grey-light has-text-weight-semibold  marginBottom6"> HWS </p>
                            <div className="  ">
                                <p>{heightString ? "Height · " + heightString : null}</p>
                                <p>{weightString ? "Weight · " + weightString : null}</p>
                                <p>{shoeSizeString ? "ShoeSize · " + shoeSizeString : null}</p>
                            </div>
                        </Columns.Column>
                        {clientPatterns(allPatterns)}
                    </Columns>
                    {clientPostures(allPostures)}
                    <br />
                    {clientMeasurements(c_measurements)}
                    <br />
                    {clientBodyPostureImages(clientBodyPics)}
                </Card.Content>
                <Card.Footer >
                    {/* Footer */}
                </Card.Footer>
            </Card>
            <br />
        </div >
    )

}



const preProductsHeading = () => {
    return (
        <div>
            <Heading size={6} className="marginLeft10 marginTop20 has-text-grey-lighter has-text-centered"> PRODUCTS </Heading>
            <Columns className="marginTop20">
                <Columns.Column size={4}>
                </Columns.Column>
                <Columns.Column size={4}>
                    <hr className="hrLight topMarginMinus20" />
                </Columns.Column>
                <Columns.Column size={4}>
                </Columns.Column>
            </Columns>
        </div>
    )
}

const productImages = (productsPics) => {
    //D
    // productsPics = [productPicObj1, prodPicObj2] 
    return (
        <div>
            <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom6"> IMAGES </p>
            <Tile kind="ancestor" className=' printBodyImageGrid  is-shadowless' >
                {/* <Tile kind='parent' className=' '>
                    <Tile className='' >
                        <Box className=' printBodyImageCard pad0  ' >
                            <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                        </Box>
                    </Tile>
                </Tile> */}
                {
                    productsPics.map(thisProductPic => {
                        return (
                            <Tile kind='parent' className=' is-shadowless'>
                                <Tile className=' is-shadowless' >
                                    <Box className=' printBodyImageCard pad0  is-shadowless' >
                                        <Image rounded={false} className='  ' src={thisProductPic.url} />
                                    </Box>
                                </Tile>
                            </Tile>
                        )
                    })
                }
            </Tile>
        </div>
    )
}

const productMeasurements = (productMeasurements) => {
    // D
    // productMeasurements = ["measurement1_Object", "measurement2_Object"]
    return (
        <div>
            <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom8"> MEASUREMENTS </p>
            <Tile kind="ancestor" className=' printMeasurementGrid ' >
                {
                    productMeasurements.map(thisMeasurement => {
                        return measurementCard(thisMeasurement.name, thisMeasurement.measures)
                    })
                }
            </Tile>
        </div>

    )
}

const productRemarks = (remarkObjects) => {
    // D
    // remarkObjects = [remark1_Object, remark2_Object]
    return (
        <div>
            <p className="has-text-grey-light  has-text-weight-semibold marginBottom6"> REMARKS </p>
            <div className="type50">
                {/* <p className="marginBottom6">• REM 2. by STAN</p> */}
                {
                    remarkObjects.map((thisRemark, index) => {
                        return (
                            <p key={index} className="marginBottom6">• {thisRemark.remark}. {thisRemark.byName}</p>
                        )
                    })
                }
            </div>
        </div>
    )
}

const productSelections = (selectionObjects) => {
    // D
    // selectionObjects = ["selection1_Object", "selection2_Object"]

    if (!selectionObjects.length) {
        return <p className="has-text-grey-light has-text-weight-semibold  marginBottom6"> SELECTIONS </p>
    }


    return (
        <div>
            <p className="has-text-grey-light has-text-weight-semibold  marginBottom6"> SELECTIONS </p>
            <div className="  ">
                <List className=" greyBorder is-shadowless type50 has-text-weight-semibold " >
                    <List.Item>
                        {/* <p className="marginBottom6">• FIT= REGULAR</p> */}
                        {selectionObjects.length ?
                            selectionObjects.map((thisSelectionObj, index) => {
                                if (thisSelectionObj.value === "" || thisSelectionObj.value === null) {
                                    return null
                                }
                                return (
                                    <p key={index} className="marginBottom6">• {thisSelectionObj.key} = {thisSelectionObj.value}</p>
                                )
                            })
                            :
                            null
                        }
                    </List.Item>
                </List>
            </div>
        </div>
    )
}

const productDetails = (productId, productType, styleNo, trialDate, deliveryDate, materialToSource, selectionObjects, remarks, measurements, productPics) => {
    // productId = 201224ZHO-PDXK
    // productType = Bomber Jacket
    // styleNo = 26762BHJ
    // trialDateString = 31-Jan-2021
    // deliveryDateString = 4-Feb-2021
    // materialToSource = ["GFYFYF", "YVYFYFYFY"]
    // selectionObjects = ["selection1_Object", "selection2_Object"]
    // remarks = ["remark1_Object", "remark2_Object"]
    // measurements = ["measurement1_Object", "measurement2_Object"]
    // productPics = ["productImage1_ImageUrl", "productImage2_ImageUrl"]

    let trialDateString = trialDate ? dateToDateString(trialDate) : ""
    let deliveryDateString = deliveryDate ? dateToDateString(deliveryDate) : ""

    return (

        <div>
            <Card className='is-shadowless greyBorder' >
                <Card.Header className=' pad10 is-shadowless'>
                    <Heading size={6} className="has-text-grey-light marginTop10 marginLeft10 "> PRODUCT ID · {productId}  </Heading>
                </Card.Header>
                <Card.Content className=" " >

                    <div className=" marginTopMinus30">
                        <Heading size={5} className="  marginBottom0"> {productType} </Heading>
                        <Heading subtitle className=" type50 has-text-grey"> StyleNo: {styleNo} &nbsp; · &nbsp; Trial: {trialDateString} &nbsp; · &nbsp; Delivery: {deliveryDateString} </Heading>
                    </div>

                    <div className="has-text-weight-semibold marginTop30 type60">
                        <p className="has-text-grey-light "> MATERIALS </p>
                        {/* <p className=" "> GFYFYF, YVYFYFYFY </p> */}
                        {
                            materialToSource ?
                                (materialToSource.length) ?
                                    materialToSource.join(", ")
                                    : null
                                : null
                        }
                    </div>

                    <Columns className=' type60 marginTop20'>
                        <Columns.Column size={7}>
                            {productSelections(selectionObjects ?? [])}
                        </Columns.Column>
                        <Columns.Column className=''>
                            {productRemarks(remarks)}
                        </Columns.Column>
                    </Columns>
                    <br />
                    {productMeasurements(measurements)}
                    <br />
                    {productImages(productPics)}
                    <br />
                </Card.Content>
            </Card>
        </div >
    )

}


const allProducts = (products) => {
    // products = [product1, product2]  Must contain all parameter keys as described in productDetailsFucntion above
    return (
        <div>
            {
                products.map(thisProduct => {
                    return (
                        <div>
                            {
                                productDetails(
                                    thisProduct.productId,
                                    thisProduct.type,
                                    thisProduct.styleNo,
                                    thisProduct.trialDate,
                                    thisProduct.deliveryDate,
                                    thisProduct.materialToSource,
                                    thisProduct.styleSelections,
                                    thisProduct.remarks,
                                    thisProduct.measurements,
                                    thisProduct.pics
                                )
                            }
                            <br />
                        </div>
                    )
                })
            }
        </div>
    )
}


export const getPrintSheetHTML = (client, order, products, sheetType, showOrder, showClient) => {

    let showOrd = showOrder

    if (sheetType === PrintSheetType.customerSheet) {
        showOrd = false
    }

    return finalFrom(client, order, products, showOrd, showClient, sheetType)
}


const finalFrom = (client, order, products, showOrderInfo, showClientInfo, sheetType) => {
    // sheetType = ORDER DETAILS  |  PRODUCT DETIALS


    // PUT BEFORE BOX HTML .. with bulma cdn link and also our stylesheet link (can upload it on aws)
    // PUT AFTER BOX HTML..
    // TRY FETCHING ALL ORDER DETAILS ON CLOUD CODE AND BUILDING THIS CODE




    return (
        <Box className=" has-background-light">
            <br />
            {pageHeader(sheetType, clientLogoUrl)}
            {showOrderInfo ?
                orderHeader(
                    order.orderNo,
                    order.posNo,
                    products.length ? products.length : "",
                    order.trialDate,
                    order.deliveryDate)
                : null}

            <br />

            {showClientInfo ?
                <div>
                    {

                        // name, 
                        // height, 
                        // weight, 
                        // shoeSize, 
                        // heightUnit, 
                        // weightUnit, 
                        // shoeSizeUnit, 
                        // patterns, 
                        // postures, 
                        // measurements, 
                        // pics)


                        clientDetails(
                            client.name,
                            client.measurementNote ?? "",
                            client.height,
                            client.weight,
                            client.shoeSize,
                            "Inchs",
                            "Kgs",
                            "Inchs",
                            client.customMadePatterns,
                            client.selectedPostures,  // selectedPostures
                            client.measurements,
                            client.pics
                        )
                    }
                    {
                        products.length ?
                            preProductsHeading()
                            : null
                    }
                </div>

                : null}


            {products.length ?
                allProducts(products)
                : null
            }
            <br />
        </Box>

    )

}




const form = () => {

    return (
        <Box className=" has-background-light">

            <br />

            {pageHeader("ORDER DETAILS", "clientLogoUrl")}

            {orderHeader("201224ZHO", "112212", "2", "31-Jan-2021", "4-Feb-2021")}

            <br />


            <Card className=' has-background-light marginTop10   '>
                <Card.Header className=' pad10 is-shadowless'>
                    <Heading size={6} className="has-text-grey-light marginTop10 marginLeft10 "> Client: Elon Musk </Heading>
                </Card.Header>
                <Card.Content >
                    <Columns className=' type60'>
                        <Columns.Column className=''>
                            <p className="has-text-grey-light has-text-weight-semibold  marginBottom6"> HWS </p>
                            {/* <List className=" has-background-light topMarginMinus20 type60" >
                                    <List.Item> */}
                            <div className="  ">
                                <p>Height · 175 cm</p>
                                <p>Weight · 70 kg</p>
                                <p>ShoeSize · 8 inch</p>
                            </div>

                            {/* </List.Item>
                                </List> */}
                        </Columns.Column>
                        <Columns.Column className=''>
                            <p className="has-text-grey-light  has-text-weight-semibold marginBottom6"> PATTERNS </p>
                            {/* <List className=" has-background-light topMarginMinus20 type60" >
                                    <List.Item> */}
                            <div className=" ">
                                <p>Jacket · 164672</p>
                                <p>Suit 2 Peace · 164672</p>
                            </div>

                            {/* </List.Item>
                                </List> */}
                        </Columns.Column>
                    </Columns>


                    <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom6"> POSTURES </p>
                    <Tile kind="ancestor" className=' printPostureGrid ' >
                        <Tile kind='parent' className=' '>
                            <Tile className='' >
                                <Box className=' printPostureCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printPostureCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printPostureCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printPostureCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printPostureCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                    </Tile>

                    <br />

                    <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom8"> MEASUREMENTS </p>
                    <Tile kind="ancestor" className='  is-shadowless printMeasurementGrid ' >

                        <Tile kind='parent' className=' is-shadowless'>
                            <Tile className=' is-shadowless' >
                                <div className=' printMeasuremntCard pad0 has-background-light is-shadowless' >
                                    <List className=" has-background-light is-shadowless  type50 has-text-weight-semibold " >
                                        <p className=" pad10  type50     "> MEASUREMENT NAME </p>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                    </List>
                                </div>
                            </Tile>
                        </Tile>


                        <Tile kind='parent' className=' '>
                            <Tile className='' >
                                <div className=' printMeasuremntCard pad0 has-background-light ' >
                                    <List className=" has-background-light  type50 has-text-weight-semibold " >
                                        <p className=" pad10  type50     "> MEASUREMENT NAME </p>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>



                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                    </List>
                                </div>
                            </Tile>
                        </Tile>

                        <Tile kind='parent' className=' '>
                            <Tile className='' >
                                <div className=' printMeasuremntCard pad0 has-background-light ' >
                                    <List className=" has-background-light  type50 has-text-weight-semibold " >
                                        <p className=" pad10  type50     "> MEASUREMENT NAME </p>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height sjsbkjbs dbdjbdkd djdbdkjbd dkjbdkjd</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                    </List>
                                </div>
                            </Tile>
                        </Tile>


                    </Tile>


                    <br />

                    <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom6"> POSTURE IMAGES </p>
                    <Tile kind="ancestor" className=' printBodyImageGrid ' >
                        <Tile kind='parent' className=' '>
                            <Tile className='' >
                                <Box className=' printBodyImageCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printBodyImageCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printBodyImageCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printBodyImageCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printBodyImageCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                    </Tile>



                </Card.Content>
                <Card.Footer >
                    {/* Footer */}
                </Card.Footer>
            </Card>

            <br />
            <Heading size={6} className="marginLeft10 marginTop20 has-text-grey-lighter has-text-centered"> PRODUCTS </Heading>

            <Columns className="marginTop20">
                <Columns.Column size={4}>
                </Columns.Column>
                <Columns.Column size={4}>
                    <hr className="hrLight topMarginMinus20" />
                </Columns.Column>
                <Columns.Column size={4}>
                </Columns.Column>
            </Columns>


            <Card className='    '>
                <Card.Header className=' pad10 is-shadowless'>
                    <Heading size={7} className="has-text-grey-light marginTop10 marginLeft10 "> PRODUCT ID · 201224ZHO-PDXK  </Heading>
                </Card.Header>
                <Card.Content className=" " >

                    <div className=" marginTopMinus30">
                        <Heading size={5} className="  marginBottom0"> Bomber Jacket </Heading>
                        <Heading subtitle className=" type50 has-text-grey"> StyleNo: 26762BHJ &nbsp; · &nbsp; Trial: 31-Jan-2021 &nbsp; · &nbsp; Delivery: 4-Feb-2021 </Heading>
                    </div>


                    <div className="has-text-weight-semibold marginTop30 type60">
                        <p className="has-text-grey-light "> MATERIALS </p>
                        <p className=" "> GFYFYF, YVYFYFYFY </p>
                    </div>



                    <Columns className=' type60 marginTop20'>
                        <Columns.Column size={7}>
                            <p className="has-text-grey-light has-text-weight-semibold  marginBottom6"> SELECTIONS </p>
                            {/* <List className=" has-background-light topMarginMinus20 type60" >
                                    <List.Item> */}
                            <div className="  ">
                                <List className="   type50 has-text-weight-semibold " >
                                    <List.Item>
                                        <p className="marginBottom6">• FIT= REGULAR</p>
                                        <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
                                        <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
                                        <p className="marginBottom6">• FIT= REGULAR</p>
                                        <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
                                        <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
                                        <p className="marginBottom6">• FIT= REGULAR</p>
                                        <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
                                        <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
                                        <p className="marginBottom6">• FIT= REGULAR</p>
                                        <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
                                        <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
                                        <p className="marginBottom6">• FIT= REGULAR</p>
                                        <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
                                        <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
                                    </List.Item>
                                </List>

                            </div>

                            {/* </List.Item>
                                </List> */}
                        </Columns.Column>
                        <Columns.Column className=''>
                            <p className="has-text-grey-light  has-text-weight-semibold marginBottom6"> REMARKS </p>
                            {/* <List className=" has-background-light topMarginMinus20 type60" >
                                    <List.Item> */}
                            <div className="type50">
                                <p className="marginBottom6">• REM 2. by STAN</p>
                                <p className="marginBottom6">• REM 2. by STAN</p>
                            </div>

                            {/* </List.Item>
                                </List> */}
                        </Columns.Column>
                    </Columns>


                    <br />

                    <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom8"> MEASUREMENTS </p>
                    <Tile kind="ancestor" className=' printMeasurementGrid ' >

                        <Tile kind='parent' className=' '>
                            <Tile className='' >
                                <div className=' printMeasuremntCard pad0  ' >
                                    <List className=" has-background-white-bis  type50 has-text-weight-semibold " >
                                        <p className=" pad10  type50     "> MEASUREMENT NAME </p>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                    </List>
                                </div>
                            </Tile>
                        </Tile>


                        <Tile kind='parent' className=' '>
                            <Tile className='' >
                                <div className=' printMeasuremntCard pad0  ' >
                                    <List className=" has-background-white-bis  type50 has-text-weight-semibold " >
                                        <p className=" pad10  type50     "> MEASUREMENT NAME </p>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>



                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                    </List>
                                </div>
                            </Tile>
                        </Tile>

                        <Tile kind='parent' className=' '>
                            <Tile className='' >
                                <div className=' printMeasuremntCard pad0  ' >
                                    <List className="  has-background-white-bis type50 has-text-weight-semibold " >
                                        <p className=" pad10  type50     "> MEASUREMENT NAME </p>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height sjsbkjbs dbdjbdkd djdbdkjbd dkjbdkjd</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Height</Columns.Column>
                                                <Columns.Column className="has-text-right">  175 cm </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> Weight</Columns.Column>
                                                <Columns.Column className="has-text-right">  70 kg </Columns.Column>
                                            </Columns>
                                        </List.Item>
                                        <List.Item>
                                            <Columns className="is-mobile">
                                                <Columns.Column size={8}> ShoeSize</Columns.Column>
                                                <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
                                            </Columns>
                                        </List.Item>

                                    </List>
                                </div>
                            </Tile>
                        </Tile>


                    </Tile>


                    <br />

                    <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom6"> IMAGES </p>
                    <Tile kind="ancestor" className=' printBodyImageGrid ' >
                        <Tile kind='parent' className=' '>
                            <Tile className='' >
                                <Box className=' printBodyImageCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printBodyImageCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printBodyImageCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printBodyImageCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                        <Tile kind='parent' className=''>
                            <Tile className='' >
                                <Box className=' printBodyImageCard pad0  ' >
                                    <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
                                </Box>
                            </Tile>
                        </Tile>
                    </Tile>



                </Card.Content>
            </Card>






        </Box>
    )
}