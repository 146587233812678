import React from 'react'
import Orders from '../../../General/Orders'

const RecieveOrders = (props) => {

    return (
        <div>
            <Orders headerTitle='Orders that are sent by Factory and need to be Recieved.' screen={props.screen} toggleTabs={props.toggleTabs} />
        </div>
    ) 
}

export default RecieveOrders