import React from 'react'
import Orders from '../../../General/Orders'


const SendOrders = (props) => {


    console.log('SCREEN RECIEVED IN SEND ORDERS = '+  props.screen)

    return (
        <div>
            <Orders headerTitle='Orders that are created and not sent to Factory yet.' screen={props.screen} toggleTabs={props.toggleTabs} />
        </div>
    ) 
}

export default SendOrders