import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Tabs, Image, Columns } from 'react-bulma-components/dist';
import SendOrders from './send-orders'
import RecieveOrders from './recieve-orders'

import Aux from '../../../containers/Aux'
import ColumnAux from '../../../containers/ColumnAux'
import { screen } from '../../../utilities/enums'
import { getCurrentUser } from '../../../utilities/Store';


const FactoryWindow = () => {

    let cu = getCurrentUser()


    //factoryTab
    const factoryTab = {
        sendOrders: 'Send Orders',
        recieveOrders: 'Recieve Orders',
    }


    const [currentScreen, setCurrentScreen] = useState(screen.SendOrders_Store_Factory)
    const [currentTab, setCurrentTab] = useState(factoryTab.sendOrders)
    const [showTabs, setShowTabs] = useState(true)

    const toggleTabs = (val) => {
        setShowTabs(val ?? !showTabs)
    }


    let tabs = (
        <Tabs
            //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
            fullwidth={true}
            align='centered'
            size='small'
            className="boldTabs has-text-black marginTop10"
        >
            {cu.position.control.ordersControl.storeControl.sendOrders || cu.isAdmin ? <Tabs.Tab active={currentTab === factoryTab.sendOrders} onClick={() => { changeTab(factoryTab.sendOrders) }} >  Send Orders </Tabs.Tab> : null}

            {cu.position.control.ordersControl.storeControl.recieveOrders || cu.isAdmin ? <Tabs.Tab active={currentTab === factoryTab.recieveOrders} onClick={() => { changeTab(factoryTab.recieveOrders) }} > Recieve Orders </Tabs.Tab> : null}


        </Tabs>
    )



    const changeTab = (tab) => {
        if (tab === factoryTab.sendOrders) {
            setCurrentScreen(screen.SendOrders_Store_Factory)
        } else {
            setCurrentScreen(screen.RecieveOrders_Store_Factory)
        }
        setCurrentTab(tab)
    }


    const header = () => {

        return (
            <Aux>
                {/* <Heading className='has-text-bold type74'> Vendore Window </Heading> */}
                <Columns>
                    {/* <Columns.Column size={1} className="is-narrow">
                        <Image src={profilePicUrl} alt="Customer Profile Pic" size={64} rounded />
                    </Columns.Column> */}
                    <Columns.Column >
                        {showTabs ? tabs : null}
                    </Columns.Column>
                </Columns>
            </Aux>

        )
    }


    const page = () => {
        return (
            <div>
                {header()}
                {(currentTab === factoryTab.sendOrders) ?
                    <ColumnAux size={8} isMiddle={true} >
                        <SendOrders screen={currentScreen}  toggleTabs={toggleTabs}  />
                    </ColumnAux>
                    :
                    <ColumnAux size={8} isMiddle={true} >
                        <RecieveOrders screen={currentScreen}  toggleTabs={toggleTabs}  />
                    </ColumnAux>
                }
            </div>
        )
    }


    return (
        page()
    )



}

export default FactoryWindow