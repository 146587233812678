import React, { useState, useRef } from 'react'
import Orders from '../../General/Orders'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Button, Image, Columns, Tabs } from 'react-bulma-components/dist';
import { screen, SearchKey } from '../../utilities/enums'
import { OrderFilter } from '../../utilities/Filters'
import { Order } from '../../utilities/Classes'
import OrderCard from '../../components/Cards/OrderCard'
import ProductsOfOrder from '../../containers/ProductsOfOrder'
import ColumnAux from '../../containers/ColumnAux'
import DatePicker from 'react-datepicker'

const RecentOrders = (props) => {


        //vendorTab
        const recentTab = {
            Recent: 'Recent All',
            Today: 'Today',
            InfoPending: 'InfoPending',
            InfoComplete: 'Recent InfoComplete',
            Unprocessed: 'Unprocessed',
        }
    
    
        const [params, setParams] = useState("")
        const [currentTab, setCurrentTab] = useState(recentTab.Recent)

        const [showTabs, setShowTabs] = useState(true)

        const toggleTabs = (val) => {
            setShowTabs(val ?? !showTabs)
        }
    
        let tabs = (
            <Tabs
                //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
                fullwidth={true}
                align='centered'
                size='small'
                className="boldTabs has-text-black marginTop10"
            >
                <Tabs.Tab active={currentTab === recentTab.Recent} onClick={() => { changeTab(recentTab.Recent) }} > {recentTab.Recent} </Tabs.Tab>
                <Tabs.Tab active={currentTab === recentTab.Today} onClick={() => { changeTab(recentTab.Today) }} >  {recentTab.Today} </Tabs.Tab> 
                <Tabs.Tab active={currentTab === recentTab.InfoPending} onClick={() => { changeTab(recentTab.InfoPending) }} >  {recentTab.InfoPending} </Tabs.Tab> 
                <Tabs.Tab active={currentTab === recentTab.InfoComplete} onClick={() => { changeTab(recentTab.InfoComplete) }} >  {recentTab.InfoComplete} </Tabs.Tab> 
                <Tabs.Tab active={currentTab === recentTab.Unprocessed} onClick={() => { changeTab(recentTab.Unprocessed) }} >  {recentTab.Unprocessed} </Tabs.Tab>
            </Tabs>
        )
    
    
        const changeTab = (tab) => {

            console.log("TAB");
            console.log(tab);


            if (tab === recentTab.Recent){
                setParams("")
            }
            if (tab === recentTab.Today){
                setParams("Today")
            }
            if (tab === recentTab.InfoPending){
                setParams("Info Pending")
            }
            if (tab === recentTab.InfoComplete){
                setParams("Recent Info Complete")
            }
            if (tab === recentTab.Unprocessed){
                setParams("Unprocessed")
            }
            
            setCurrentTab(tab)
        }
    
    
        const header = () => {
            return (
                <div>
                    {/* <Heading className='has-text-bold type74'> Vendore Window </Heading> */}
                    <Columns>
                        {/* <Columns.Column size={1} className="is-narrow">
                            <Image src={profilePicUrl} alt="Customer Profile Pic" size={64} rounded />
                        </Columns.Column> */}
                        <Columns.Column >
                            {showTabs ? tabs : null}
                        </Columns.Column>
                    </Columns>
                </div>
            )
        }
    


    return (
        <div>
            {header()}
            <br />
            <br />

            <Orders columnAuxSize={8} isSearchable={false} screen={screen.RecentOrders} params={params} toggleTabs={toggleTabs} />
        </div>
    )
}

export default RecentOrders