import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Tabs, Image, Columns } from 'react-bulma-components/dist';
import SendOrders from '../Store/VendorWindow/send-orders-vendor'
import RecieveOrders from '../Store/VendorWindow/recieve-orders-vendor'

import Aux from '../../containers/Aux'
import ColumnAux from '../../containers/ColumnAux'
import { screen, departmentForscreen } from '../../utilities/enums'
import { getCurrentUser } from '../../utilities/Store';


const FactoryVendorWindow = (props) => {

    let cu = getCurrentUser()


    //vendorTab
    const vendorTab = {
        sendOrders: 'Send Orders',
        recieveOrders: 'Recieve Orders',
    }


    const [currentScreen, setCurrentScreen] = useState(screen.SendOrders_FactoryVendorWindow)
    const [currentTab, setCurrentTab] = useState(vendorTab.sendOrders)
    const [showTabs, setShowTabs] = useState(true)

    const toggleTabs = (val) => {
        setShowTabs(val ?? !showTabs)
    }

    let tabs = (
        <Tabs
            //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
            fullwidth={true}
            align='centered'
            size='small'
            className="boldTabs has-text-black marginTop10"
        >


            {cu.position.control.ordersControl.storeControl.sendOrders || cu.isAdmin ? <Tabs.Tab active={currentTab === vendorTab.sendOrders} onClick={() => { changeTab(vendorTab.sendOrders) }} >  Send Orders </Tabs.Tab> : null}

            {cu.position.control.ordersControl.storeControl.recieveOrders || cu.isAdmin ? <Tabs.Tab active={currentTab === vendorTab.recieveOrders} onClick={() => { changeTab(vendorTab.recieveOrders) }} > Recieve Orders </Tabs.Tab> : null}
        </Tabs>
    )



    const changeTab = (tab) => {
        if (tab === vendorTab.sendOrders) {
            setCurrentScreen(screen.SendOrders_FactoryVendorWindow)
        } else {
            setCurrentScreen(screen.RecieveOrders_FactoryVendorWindow)
        }
        setCurrentTab(tab)
    }


    const header = () => {

        return (
            <Aux>
                {/* <Heading className='has-text-bold type74'> Vendore Window </Heading> */}
                <Columns>
                    {/* <Columns.Column size={1} className="is-narrow">
                        <Image src={profilePicUrl} alt="Customer Profile Pic" size={64} rounded />
                    </Columns.Column> */}
                    <Columns.Column >
                        {showTabs ? tabs : null}
                    </Columns.Column>
                </Columns>
            </Aux>

        )
    }


    const page = () => {
        return (
            <div>
                {header()}
                {(currentTab === vendorTab.sendOrders) ?
                    <ColumnAux size={8} isMiddle={true} >
                        <SendOrders screen={currentScreen} toggleTabs={toggleTabs} />
                    </ColumnAux>
                    :
                    <ColumnAux size={8} isMiddle={true} >
                        <RecieveOrders screen={currentScreen} toggleTabs={toggleTabs} />
                    </ColumnAux>
                }
            </div>
        )
    }


    return (
        page()
    )



}

export default FactoryVendorWindow