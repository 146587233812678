import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Tabs, Image, Columns } from 'react-bulma-components/dist';
import TrialOrders from './trial-orders'
import FinishingOrders from './finishing-orders'
import DeliveryOrders from './delivery-orders'

import Aux from '../../../containers/Aux'
import ColumnAux from '../../../containers/ColumnAux'
import { screen } from '../../../utilities/enums'
import { getCurrentUser } from '../../../utilities/Store';


const ClientWindow = () => {

    let cu = getCurrentUser()


    //vendorTab
    const clientTab = {
        Trial: 'Trial',
        Finishing: 'Finishing',
        Delivery: 'Delivery',
    }


    const [currentScreen, setCurrentScreen] = useState(screen.ApproveTrial)
    const [currentTab, setCurrentTab] = useState(clientTab.Trial)
    const [showTabs, setShowTabs] = useState(true)

    const toggleTabs = (val) => {
        setShowTabs(val ?? !showTabs)
    }

    let tabs = (
        <Tabs
            //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
            fullwidth={true}
            align='centered'
            size='small'
            className="boldTabs has-text-black marginTop10"
        >
            {cu.position.control.ordersControl.storeControl.approveTrial || cu.isAdmin ? <Tabs.Tab active={currentTab === clientTab.Trial} onClick={() => { changeTab(clientTab.Trial) }} >   Trial </Tabs.Tab> : null}
            {cu.position.control.ordersControl.storeControl.approveFinishing || cu.isAdmin ? <Tabs.Tab active={currentTab === clientTab.Finishing} onClick={() => { changeTab(clientTab.Finishing) }} > Finishing </Tabs.Tab> : null}
            {cu.position.control.ordersControl.storeControl.approveDelivery || cu.isAdmin ? <Tabs.Tab active={currentTab === clientTab.Delivery} onClick={() => { changeTab(clientTab.Delivery) }} > Delivery </Tabs.Tab> : null}
        </Tabs>
    )



    const changeTab = (tab) => {
        if (tab === clientTab.Trial) {
            setCurrentScreen(screen.ApproveTrial)
        }
        else if (tab === clientTab.Finishing) {
            setCurrentScreen(screen.ApproveFinishing)
        }
        else {
            setCurrentScreen(screen.ApproveDelivery)
        }
        setCurrentTab(tab)
    }


    const header = () => {
        return (
            <Aux>
                {/* <Heading className='has-text-bold type74'> Vendore Window </Heading> */}
                <Columns>
                    {/* <Columns.Column size={1} className="is-narrow">
                        <Image src={profilePicUrl} alt="Customer Profile Pic" size={64} rounded />
                    </Columns.Column> */}
                    <Columns.Column >
                        {showTabs ? tabs : null}
                    </Columns.Column>
                </Columns>
            </Aux>
        )
    }

    const aptSection = () => {
        switch (currentTab) {
            case clientTab.Trial: return <TrialOrders screen={currentScreen}  toggleTabs={toggleTabs} />;
            case clientTab.Finishing: return <FinishingOrders screen={currentScreen}  toggleTabs={toggleTabs} />;
            default: return <DeliveryOrders screen={currentScreen}  toggleTabs={toggleTabs} />;
        }
    }

    const page = () => {
        return (
            <div>
                {header()}
                {<ColumnAux size={8} isMiddle={true} >
                    {aptSection()}
                </ColumnAux>
                }
            </div>
        )
    }


    return (
        page()
    )



}

export default ClientWindow